import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import { FC } from 'react';
import { Layout } from '../components/Layout';
import { TalesList } from '../components/TalesList';
import { ImagesTalePreviewData, TextTalePreviewData } from '../types';
import { getFieldsForTalePreview } from '../helpers/getFieldsForTalePreview';

interface DataProps {
    site: {
        siteMetadata: {
            title: string
        }
    }
    allTextTales: {
        nodes: TextTalePreviewData[];
    },
    allImagesTales: {
        nodes: ImagesTalePreviewData[];
    }
}

const NotFound: FC<PageProps<DataProps>> = ({ data: {allTextTales, allImagesTales, site}, location }) => {
    const textTales = allTextTales.nodes.map(getFieldsForTalePreview('text'));
    const imagesTales = allImagesTales.nodes.map(getFieldsForTalePreview('images'));

    return (
        <Layout seoTitle='Ничего не найдено' location={location}>
            <h1 style={{marginTop: 0}}>Ничего не найдено</h1>
            <p>К сожалению по вашему запросу ничего не найдено. Возможно вам понравятся сказки, которые интересны
                большинству наших читателей</p>
            <TalesList title="Популярные сказки" list={[...textTales, ...imagesTales]} />
        </Layout>
    );
};

export default NotFound;

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
         allTextTales(filter: {visits: {gte: 1}}) {
            nodes {
                _id
                slug
                name
                description
                timeToRead
                author_id
            }
        }
        allImagesTales(limit: 10) {
            nodes {
                _id
                author_id
                name
                slug
                visits
                images_amount
            }
        }
    }
`;
